<template>
  <div>
    <v-breadcrumbs :items="crumbs"></v-breadcrumbs>
    <base-loader :isLoading="isLoading"></base-loader>
    <div class="text-right">
      <v-spacer></v-spacer>
      <v-btn
        class="mr-2"
        dark
        color="primary"
        @click="$router.push('/consultant/users')"
        ><v-icon>mdi-arrow-left</v-icon>Back to Existing clients</v-btn
      >
    </div>

    <v-row>
      <v-col md="6">
        <v-card>
          <v-card-text>
            <h4 class="text-md-h4 primary--text mb-5">
              {{ `${isEdit ? "Edit" : "New"} Company` }}
            </h4>
            <v-form ref="companyForm" @submit.prevent="save()">
              <v-row>
                <v-col cols="12"
                  ><v-switch label="Status" v-model="companyModel.status"
                /></v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="companyModel.name"
                    label="Company Name"
                    required
                    outlined
                    :rules="[(v) => !!v || 'Company Name is required']"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="companyModel.companyReg"
                    label="Company Registration Number"
                    required
                    outlined
                    :rules="[(v) => !!v || 'Company Reg is required']"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="companyModel.companyContactPerson"
                    label="Contact Person Full Name"
                    required
                    outlined
                    :rules="[(v) => !!v || `Contacts fullname is required`]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="companyModel.companyContactPersonEmail"
                    label="Contact's Email"
                    required
                    outlined
                    :rules="[(v) => !!v || `Contact's email is required`]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="companyModel.fullGoogleAddress"
                    label="Companies Address"
                    required
                    outlined
                    :rules="[(v) => !!v || `Companies address is required`]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    label="Notes"
                    v-model="companyModel.notes"
                    outlined
                    counter
                  />
                </v-col>
              </v-row>
              <v-btn type="submit" color="success">Save</v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { gql } from "apollo-boost";
import { Company } from "@/models/Company";
export default {
  data() {
    return {
      isEdit: false,
      isLoading: false,
      companyModel: new Company(),
      me: "",
      search: null,
    };
  },
  created() {
    this.isEdit = this.$route.params.id != 0;
    this.isLoading = this.isEdit ? true : false;
  },
  computed: {
    crumbs() {
      return [
        {
          text: "Companies",
          disabled: false,
          href: "/consultant/companies",
        },
        {
          text: "Company Detail",
          disabled: false,
          href: `/consultant/company/${this.$route.params.id}`,
        },
        {
          text: `${this.isEdit ? "Edit" : "New"} Company`,
          disabled: true,
        },
      ];
    },
  },
  watch: {},
  apollo: {
    me: gql`
      query {
        me {
          id
        }
      }
    `,
    company: {
      query: gql`
        query Company($id: ID!) {
          company(id: $id) {
            id
            name
            companyReg
            companyContactPerson
            companyContactPersonEmail
            fullGoogleAddress
            notes
            status
          }
        }
      `,
      fetchPolicy: "network-only",
      skip() {
        return !this.isEdit;
      },
      variables() {
        const self = this;
        return {
          id: self.$route.params.id,
        };
      },
      result(response, key) {
        this.isLoading = false;
        this.companyModel.id = this.company.id;
        this.companyModel.name = this.company.name;
        this.companyModel.fullGoogleAddress = this.company.fullGoogleAddress;
        this.companyModel.companyContactPerson =
          this.company.companyContactPerson;
        this.companyModel.companyContactPersonEmail =
          this.company.companyContactPersonEmail;
        this.companyModel.companyReg = this.company.companyReg;
        this.companyModel.notes = this.company.notes;
      },
    },
  },
  methods: {
    async save() {
      this.isLoading = true;
      const self = this;
      if (!self.$refs.companyForm.validate()) {
        self.$swal("Error", "Validation failed", "error");
        self.isLoading = false;
        return;
      }
      if (self.isEdit) {
        // update
        await this.updateCompany()
          .then(() => {
            this.$swal("Success!", "Company was updated", "success");
          })
          .catch((e) => {
            this.$swal(
              "Error!",
              "something went wrong when trying to update the company, check data and try again later!",
              "error"
            );
          });
      } else {
        // create
        await this.createCompany()
          .then(() => {
            this.$swal("Success!", "Company was created", "success");
            window.location.href = `/consultant/companies`;
          })
          .catch((e) => {
            this.$swal(
              "Error!",
              `Something went wrong when creating the company, check data and try again later!`,
              "error"
            );
          });
      }
      this.isLoading = false;
    },
    async updateCompany() {
      const self = this;
      try {
        const response = await self.$apollo.mutate({
          mutation: gql`
            mutation update($company: CompanyUpdateInput!) {
              updateCompany(company: $company) {
                id
                updated
              }
            }
          `,
          variables: {
            company: {
              id: self.companyModel.id,
              name: self.companyModel.name,
              updatedBy: self.me.id,
              status: self.companyModel.status,
              companyContactPerson: self.companyModel.companyContactPerson,
              companyContactPersonEmail:
                self.companyModel.companyContactPersonEmail,
              notes: self.companyModel.notes,
              fullGoogleAddress: self.companyModel.fullGoogleAddress,
              companyReg: self.companyModel.companyReg,
            },
          },
        });
        if (!response) {
          throw "Something went wrong when creating the company!";
        }
        if (response.errors && response.errors.length > 0) {
          throw response.errors[0];
        }
        this.$swal(
          "Success!",
          "Company was successfully updated, redirecting you",
          "success"
        );
        setTimeout(() => {
          this.$router.replace(`/consultant/companies`);
        }, 2000);
      } catch (e) {
        throw e.message;
      }
    },
    async createCompany() {
      const self = this;
      try {
        const response = await self.$apollo.mutate({
          mutation: gql`
            mutation createCompany($company: CompanyCreateInput!) {
              createCompany(company: $company) {
                id
              }
            }
          `,
          variables: {
            company: {
              name: self.companyModel.name,
              createdBy: self.me.id,
              status: self.companyModel.status,
              companyContactPerson: self.companyModel.companyContactPerson,
              companyContactPersonEmail:
                self.companyModel.companyContactPersonEmail,
              notes: self.companyModel.notes,
              fullGoogleAddress: self.companyModel.fullGoogleAddress,
              companyReg: self.companyModel.companyReg,
            },
          },
        });

        if (!response) {
          throw "Something went wrong when creating the Company Item!";
        }
        if (response.errors && response.errors.length > 0) {
          throw response.errors[0];
        }
        if (response && response.data.createCompany) {
          self.companyModel.id = response?.data?.createCompany?.id;
          this.$swal(
            "Success!",
            "Company was successfully created, redirecting you",
            "success"
          );
          setTimeout(() => {
            this.$router.replace(`/consultant/companies`);
          }, 2000);
        }
      } catch (e) {
        throw e.message;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
